<template>
  <div class="iframe_liveness" style="overflow: hidden; margin-top: 4rem;">
    <div>
      <iframe 
        ref="iframe" 
        title="React Component" 
        width="435px" 
        height="400px" 
        :src="reactAppUrl" 
        frameborder="0"
        allow="camera; microphone" 
        >
      </iframe>
    </div>
    <!-- Uncomment below if needed
    <div style="text-align:center">
      <span> Your Liveness Score is : {{ Confidence }} </span>
    </div> -->
  </div>
</template>


<script>
export default {
  data() {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    return {
      id: id,
      // reactAppUrl: "http://localhost:3000/",
      Confidence: 0,
      // openIframeUrl: true,
    };
  },
  created() {
    // this.openIframeUrl = true;
  },
  computed: {
    reactAppUrl() {
      return `https://amplify-liveness.algofolks.com/?id=${this.id}`;
      // return `http://localhost:3000/?id=${this.id}`;
    }
  },
  mounted() {
    window.addEventListener("message", (event) => {
      if (
        // event.origin === "http://localhost:3000"
        event.origin === "https://amplify-liveness.algofolks.com"
      ) {
        console.log("Received message:", event.data);

        if (event.data && event.data.type === "faceLivenessScore" && "faceLivenessScore" in event.data) {
          this.Confidence = event.data.faceLivenessScore || 0;
          console.log("Confidence score:", this.Confidence);

          localStorage.setItem("faceLivenessScore", this.Confidence);
        } else {
          console.error("Unexpected response structure:", event.data);
        }

        this.openIframeUrl = false;
      }
    });
  },
    methods: { },
  };
</script>

<style></style>
