<template>
  <section class="form-section">
    <div class="">
      <div class="for-camera d-flex justify-content-center align-items-center">

        <img ref="screenshotImage" alt="Screenshot" v-if="captured" />

        <div style="position: absolute">
          <vue-webrtc id="call-canvas" :roomId="roomId" ref="webrtc2" cameraHeight="350" cameraWidth="700"
            v-on:share-started="shareStarted" v-on:share-stopped="leftRoom" v-on:left-room="leftRoom"
            v-on:joined-room="joinedRoom" width="100%" class="d-flex flex-row" />
        </div>
        <!-- <div style="position: absolute" v-if="step4" >
          <livenessCheck />
        </div> -->
      </div>
      <div class="for-camera d-flex justify-content-center align-items-center">

        <!-- <img ref="screenshotImage" alt="Screenshot" v-if="captured"/>

        <div style="position: absolute" v-if="!step3">
          <vue-webrtc
            id="call-canvas"
            :roomId="roomId"
            ref="webrtc2"
             cameraHeight="350"
            cameraWidth="700"
            v-on:share-started="shareStarted"
  
            v-on:share-stopped="leftRoom"
            v-on:left-room="leftRoom"
            v-on:joined-room="joinedRoom"
            width="100%"
            class="d-flex flex-row"
          /> 
        </div> -->
        <div style="position: absolute" v-if="step4">
          <livenessCheck @human-recognition-percentage="handleRecognitionPercentage" />
        </div>
      </div>
      <!-- <div class="login-header p-2 d-flex justify-content-between">
        <div class="r-live d-flex">
          <div class="r-icon text-danger">
            <span class="material-symbols-outlined">
              radio_button_checked
            </span>
          </div>
          <div class="r-live-h fw-bold mx-2">Recording live</div>
        </div>
        <div class="r-timer text-danger">05:34</div>
      </div>
      <div class="camera d-flex" v-if="step1">
        <div class="av-test d-flex justify-content-between">
          <div class="av-div1 d-flex">
            <span class="material-symbols-outlined"> rotate_right </span>
            <span class="mx-2 fw-bold">Uploaded PAN quality</span>
          </div>
          <button class="av-test-btn d-flex" @click="navigateToStep(2)">
            <span class="material-symbols-outlined"> play_arrow </span>
            <div class="av-test-btn-text fs-bold">Test</div>
          </button>
        </div>
      </div>
      <div class="camera d-flex" v-if="step2">
        <div class="av-test d-flex justify-content-between">
          <div class="av-div1 d-flex">
            <span class="material-symbols-outlined"> rotate_right </span>
            <span class="mx-2 fw-bold">Live photograph quality</span>
          </div>
          <button class="av-test-btn d-flex" @click="navigateToStep(3)">
            <span class="material-symbols-outlined"> play_arrow </span>
            <div class="av-test-btn-text fs-bold">Test</div>
          </button>
        </div>
      </div>
      <div class="camera d-flex" v-if="step3">
        <div class="av-test d-flex justify-content-between">
          <div class="av-div1 d-flex">
            <span class="material-symbols-outlined"> rotate_right </span>
            <span class="mx-2 fw-bold">Customer liveliness</span>
          </div>
          <button class="av-test-btn d-flex" @click="navigateToStep(4)">
            <span class="material-symbols-outlined"> play_arrow </span>
            <div class="av-test-btn-text fs-bold">Test</div>
          </button>
        </div>
      </div>
      <div class="camera d-flex" v-if="step4">
        <div class="av-test d-flex justify-content-between">
          <div class="av-div1 d-flex">
            <span class="material-symbols-outlined"> rotate_right </span>
            <span class="mx-2 fw-bold">Live captured PAN quality</span>
          </div>
          <button class="av-test-btn d-flex" @click="navigateToStep(5)">
            <span class="material-symbols-outlined"> play_arrow </span>
            <div class="av-test-btn-text fs-bold">Test</div>
          </button>
        </div>
      </div>
      <div class="camera d-flex" v-if="step5">
        <div class="av-test d-flex justify-content-between">
          <div class="av-div1 d-flex">
            <span class="material-symbols-outlined"> rotate_right </span>
            <span class="mx-2 fw-bold">Face match</span>
          </div>
          <button class="av-test-btn d-flex" @click="navigateToStep(6)">
            <span class="material-symbols-outlined"> play_arrow </span>
            <div class="av-test-btn-text fs-bold">Test</div>
          </button>
        </div>
      </div>
      <div class="camera d-flex" v-if="step6">
        <div class="av-test d-flex justify-content-between">
          <div class="av-div1 d-flex">
            <span class="material-symbols-outlined"> rotate_right </span>
            <span class="mx-2 fw-bold"
              >Random questionnaire
              <a @click="openModal" style="cursor: pointer; color: blue"
                >Question</a
              ></span
            >
          </div>
          <button
            class="av-test-btn d-flex"
            @click="navigateToIdentityCheckDone"
          >
            <span class="material-symbols-outlined"> play_arrow </span>
            <div class="av-test-btn-text fs-bold">Test</div>
          </button>
        </div>
      </div> -->

      <div v-if="showModal" class="modal">
        <div class="modal-content">
          <span class="close" @click="closeModal">&times;</span>

          <table id="customers">
            <tr>
              <th>Question</th>
              <th>Action</th>
            </tr>

            <tr v-for="(question, index) in questions" :key="index">
              <td>
                <div style="font-size: 16px;">{{ question.question }}</div>
                <div style="margin-top: 16px; font-size: 12px;">{{ question.description }}</div>
              </td>
              <td>
                <button class="normal-btn" @click="question.checked = true; question.selected = 1;"
                  :class="{ 'selected': question.selected == 1 }">
                  Matching Answer
                </button>

                <button class="normal-btn" @click="question.checked = false; question.selected = 2;"
                  :class="{ 'selected': question.selected == 2 }" style="margin-left: 12px;">Not Matching</button>
              </td>
            </tr>
          </table>

          <button @click="showSelectedOptions" style="width: 10%; margin: 0 auto; margin-top: 16px">
            Submit
          </button>
        </div>
      </div>

      <!-- <div class="i mt-2">
       <div class="i-h">RE information</div> -->
      <!-- <hr /> -->
      <!-- <div class="i-b d-flex justify-content-between">
          <div class="i-b-l">
            <div class="i-name">
              <strong>Name :</strong>{{ personalDetails.name }}
              {{ personalDetails.lastname }}
            </div>
            <div class="i-id">
              <strong>Pincode :</strong>{{ permanentAddress.pincode }}
            </div>
          </div>
          <div class="i-b-r">
            <strong>Address :</strong>{{ permanentAddress.addressLine1 }} ,
            {{ permanentAddress.city }}
          </div>
        </div> -->
      <!-- </div>  -->
    </div>
  </section>
  <!-- <div class="login-pageforBottom mt-2">   
   <div class="login-header p-2 d-flex justify-content-between align-itmes-center ">
        <div class="r-live d-flex py-2">
          <div class="r-icon text-danger">
            <span class="material-symbols-outlined">
              radio_button_checked
            </span>
          </div>
          <div class="r-live-h fw-bold mx-2">Recording live</div>
        </div>
        <div>
  <div class="  d-flex" v-if="step1">
        <div class=" align-items-center  d-flex justify-content-between">
          <div class="av-div1 d-flex">
            <span class="material-symbols-outlined"> rotate_right </span>
            <span class="mx-2 fw-bold">Uploaded PAN quality</span>
          </div>
          <button class="av-test-btn d-flex d-none" @click="navigateToStep(2)">
            <span class="material-symbols-outlined"> play_arrow </span>
            <div class="av-test-btn-text fs-bold  d-none">Test</div>
          </button>
        </div>
      </div>
      <div class="  d-flex" v-if="step2">
        <div class=" align-items-center d-flex justify-content-between">
          <div class="av-div1 d-flex">
            <span class="material-symbols-outlined"> rotate_right </span>
            <span class="mx-2 fw-bold">Live photograph quality</span>
          </div>
          <button class="av-test-btn d-flex d-none" @click="navigateToStep(3)">
            <span class="material-symbols-outlined"> play_arrow </span>
            <div class="av-test-btn-text fs-bold  d-none">Test</div>
          </button>
        </div>
      </div>
      <div class="  d-flex" v-if="step3">
        <div class=" align-items-center d-flex justify-content-between">
          <div class="av-div1 d-flex">
            <span class="material-symbols-outlined"> rotate_right </span>
            <span class="mx-2 fw-bold">Customer liveliness</span>
          </div>
          <button class="av-test-btn d-flex none" @click="navigateToStep(4)">
            <span class="material-symbols-outlined"> play_arrow </span>
            <div class="av-test-btn-text fs-bold  d-none">Test</div>
          </button>
        </div>
      </div>
      <div class="  d-flex" v-if="step4">
        <div class=" align-items-center d-flex justify-content-between">
          <div class="av-div1 d-flex">
            <span class="material-symbols-outlined"> rotate_right </span>
            <span class="mx-2 fw-bold">Live captured PAN quality</span>
          </div>
          <button class="av-test-btn d-flex d-none" @click="navigateToStep(5)">
            <span class="material-symbols-outlined"> play_arrow </span>
            <div class="av-test-btn-text fs-bold  d-none">Test</div>
          </button>
        </div>
      </div>
      <div class="  d-flex" v-if="step5">
        <div class="  align-items-center d-flex justify-content-between">
          <div class="av-div1 d-flex">
            <span class="material-symbols-outlined"> rotate_right </span>
            <span class="mx-2 fw-bold">Face match</span>
          </div>
          <button class="av-test-btn d-flex d-none" @click="navigateToStep(6)">
            <span class="material-symbols-outlined"> play_arrow </span>
            <div class="av-test-btn-text fs-bold  d-none">Test</div>
          </button>
        </div>
      </div>
      <div class="  d-flex" v-if="step6">
        <div class=" align-items-center d-flex justify-content-between">
          <div class="av-div1 d-flex">
            <span class="material-symbols-outlined"> rotate_right </span>
            <span class="mx-2 fw-bold"
              >Random questionnaire
              <a @click="openModal" style="cursor: pointer; color: blue"
                >Question</a
              ></span
            >
          </div>
          <button
            class="av-test-btn d-flex d-none"
            @click="navigateToStep(7)"
          >
            <span class="material-symbols-outlined"> play_arrow </span>
            <div class="av-test-btn-text fs-bold d-none">Test</div>
          </button>
        </div>
</div> 
        </div>
        <div class="r-timer text-danger py-2">05:34</div>
      </div>
 
    </div>  -->
  <!-- <div class="loader" v-if="isLoading">
      <div class="loader-circle"></div>
    </div> -->


</template>

<script>
import axios from "axios";
import { VueWebRTC } from "vue-webrtc";
import livenessCheck from "../components/livenessCheck.vue";
import { io } from "socket.io-client";
export default {
  name: "liveIdentityCheck",
  data() {
    return {
      hasClicked: false,
      receivedData: {},
      percentageValue: 0,
      livenessScore: 0,
      identityCheck: {
        // uploadedPanQuality: "",
        // uploadedPanQualityPercent: "",
        customerLiveliness: "",
        // livePhotographQuality: "",
        // livePhotographQualityPercent: "",
        liveCapturedPanQuality: "",
        faceMatch: "",
        faceMatchPercent: "",
        randomQuestionnaire: "",
      },
      step1: true,
      step2: false,
      step3: false,
      step4: false,
      step5: false,
      step6: false,
      customerLiveliness: 0,
      roomId: "",
      roomJoined: false,
      personalDetails: {
        name: "",
        lastname: "",
        dob: "",
      },
      permanentAddress: {
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        pincode: "",
        city: "",
      },
      showModal: false,
      questions: [],
      selectedOptions: [],
      // isLoading:false
    };
  },

  props: {

    room2Active: Boolean
  },
  components: {
    "vue-webrtc": VueWebRTC,
    livenessCheck,
  },

  created() {
    this.getDataById();
    this.getData();
    this.fetchCaseById3();
    this.roomId = this.$route?.query?.id;

    this.roomId = this.$route.query.roomid
    this.socket = io("http://localhost:3600/", {
      transports: ["websocket"],
    });

    // Listen for incoming messages
    this.socket.on("captureImage", (socketId) => {
      alert("captureImage call");
      if (this.socket.id == socketId) this.startScreenCapture();
    });
  },


  watch: {
    startStep(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);
      if (newValue >= 1) {
        if (newValue == 1 || newValue == "1") {
          this.step1 = true;
        }
        //  alert("opening camera");
        this.step = newValue;
        this.flexDivDisplay = "flex!important";
        // this.toggleRoom();
      }
    },
  },
  mounted() {
    document.title = "Live Identity Check";
    //  this.toggleRoom100();njnd

    try {
      const questionsData = JSON.parse(localStorage.getItem("randomQue"));
      if (questionsData && Array.isArray(questionsData)) {
        this.questions = questionsData;
      }
    } catch (err) {
      this.questions = [];
    }
  },

  methods: {
    showSelectedOptions() {
      console.log(this.questions);

      const newSelected = (this.questions || []).map((q) => {
        return {
          id: q.id,
          title: q.question,
          description: q.description,
          answer: q.checked ? "Good" : "recheck",
        };
      });

      console.log(newSelected);

      localStorage.setItem("RandomQueAnswer", JSON.stringify(newSelected));
      this.showModal = false;
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.selectedOptions = [];
    },
    isRandom() {
      return Math.floor(Math.random() * 1000000 + 1) % 2 == 0;
    },
    navigateToStep(stepNumber, data = {}) {

      this.isLoading = true;
      console.log("Step number:", stepNumber);

      this[`step${stepNumber - 1}`] = false;
      this[`step${stepNumber}`] = true;

      if (data) {
        this.receivedData = data;
        // alert(this.receivedData);
      }


      if (stepNumber === 2) {
        this.isLoading = true;
        this.identityCheck = {
          // uploadedPanQuality: "Good",
          // uploadedPanQualityPercent: "",
          customerLiveliness: "",
          // livePhotographQuality: "",
          // livePhotographQualityPercent: "",
          liveCapturedPanQuality: "",
          faceMatch: "",
          faceMatchPercent: "",
          randomQuestionnaire: ""
        };



      }
      if (stepNumber === 3) {

        this.identityCheck = {
          // uploadedPanQuality: "Good",
          // uploadedPanQualityPercent: "",
          customerLiveliness: "",
          // livePhotographQuality: "recheck",
          // livePhotographQualityPercent: "",
          liveCapturedPanQuality: "",
          faceMatch: "",
          faceMatchPercent: "",
          randomQuestionnaire: ""
        };

      }
      // if (stepNumber === 4) {
      //   alert("step 4");
      //   const getLivenessScore = localStorage.getItem("faceLivenessScore");
      //   this.livenessScore = getLivenessScore;
      //   console.log("this.livenessScore",this.livenessScore);

      //   // const handleRecognitionPercentage = (percentage) => {
      //   //   const result = percentage >= 100 ? 'Good' : 'recheck';
      //   //   console.log("Handling recognition percentage:", percentage, "Result:", result);
      //   //   this.identityCheck.customerLiveliness = result;
      //   // };
      //   // console.log("Received:", data);
      //   // const percentageValue = this.percentageValue || 0;
      //   // console.log("Percentage value:", percentageValue);
      //   // const customerLivelinessValue = data.liveliness || "recheck";
      //   // console.log("Customer liveliness:", customerLivelinessValue);

      //   const customerLiveliness =
      //     this.livenessScore > 60 ? "Good" : "recheck";

      //   this.identityCheck = {
      //     ...this.identityCheck,
      //     customerLiveliness: customerLiveliness,
      //     liveCapturedPanQuality: "",
      //     faceMatch: "",
      //     faceMatchPercent: "",
      //     randomQuestionnaire: ""
      //   };

      //   this.loader = false;

      //   console.log("Updated identityCheck:", this.identityCheck);

      //   // handleRecognitionPercentage(percentageValue);
      // }

      if (stepNumber === 4) {
        const checkLivenessScore = async () => {
          const getLivenessScore = localStorage.getItem("faceLivenessScore");
          if (getLivenessScore) {
            clearInterval(interval);
            this.getData();
            this.livenessScore = getLivenessScore;
            console.log("Liveness score retrieved:", this.livenessScore);
            this.roomId = this.getUserIdFromUrl();
            this.socket = io("http://localhost:3600/", {
              transports: ["websocket"],
            });
            this.socket.emit("sendSomething", { message: "Hello from frontend" });
            // Continue with the rest of the logic
            const customerLiveliness =
              parseInt(this.livenessScore, 10) > this.customerLiveliness ? "Good" : "recheck";

            this.identityCheck = {
              customerLiveliness: customerLiveliness,
              liveCapturedPanQuality: "",
              faceMatch: "",
              faceMatchPercent: "",
              randomQuestionnaire: "",
            };
            this.loader = false;
            console.log("Updated identityCheck:", this.identityCheck);

            const userId = this.getUserIdFromUrl();

            const updateData = {
              user_id: userId,
              confidence_score: this.livenessScore,
            };

            const scriptElement = document.querySelector("script[api-url]");
            const apiUrl = scriptElement.getAttribute("api-url");

            try {
              const response = await axios.post(
                `${apiUrl}/liveness`,
                updateData
              );
              console.log("Liveness score updated successfully:", response.data);
              
              setTimeout(() => {
                this.navigateToIdentityCheckDone();
              }, 3000);
            } catch (error) {
              console.error("Error updating liveness score:", error);
            }
                setTimeout(() => {
                  this.navigateToIdentityCheckDone();
                }, 3000);
              }
            };

        const interval = setInterval(checkLivenessScore, 100);
      }

      if (stepNumber === 5) {
        const { numericPanQualityRatio, getPanQuality } = this.receivedData;
        console.log("recieve data", this.receivedData);
        this.identityCheck = {
          // uploadedPanQuality: "Good",
          // uploadedPanQualityPercent: "recheck",
          customerLiveliness: "Good",
          // livePhotographQuality: "recheck",
          // livePhotographQualityPercent: "",
          liveCapturedPanQuality: numericPanQualityRatio >= getPanQuality ? "Good" : "recheck",
          faceMatch: "",
          faceMatchPercent: "",
          randomQuestionnaire: "",
        };
      }

      if (stepNumber === 6) {

        this.identityCheck = {
          // uploadedPanQuality: "Good",
          // uploadedPanQualityPercent: "recheck",
          customerLiveliness: "recheck",
          // livePhotographQuality: "recheck",
          // livePhotographQualityPercent: "",
          liveCapturedPanQuality: "recheck",
          faceMatch: "Good",
          faceMatchPercent: "",
          randomQuestionnaire: ""
        };

      }
      if (stepNumber === 7) {

        this.identityCheck = {
          // uploadedPanQuality: "Good",
          // uploadedPanQualityPercent: "recheck",
          customerLiveliness: "recheck",
          // livePhotographQuality: "recheck",
          // livePhotographQualityPercent: "",
          liveCapturedPanQuality: "recheck",
          faceMatch: "Good",
          faceMatchPercent: "",
          randomQuestionnaire: "Questions"
        };

      }
      setTimeout(() => {

        this.navigateToIdentityCheckDone();

      }, 3000);
    },

    getUserIdFromUrl() {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get("id");
    },

    async getData() {
      try {
        // this.loader = true;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.get(`${apiUrl}/setting/getSetting`);
        this.settings = response?.data?.settings[0];
        this.customerLiveliness = this.settings.customerLiveliness;
        // console.log(response)
      } catch (error) {
        console.log(error);
      }
    },

    initializeAudioContext() {
      this.audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
    },

    async checkPanImageQuality(image) {
      const formData = new FormData();
      formData.append("image", image);

      try {
        this.loader = true;
        await new Promise(resolve => setTimeout(resolve, 5000));

        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");

        const qualityResponse = await axios.post(`${apiUrl}/upload`, formData);

        const qualityRatio = qualityResponse.data.qualityRatio;
        this.numericPanQualityRatio = parseFloat(qualityRatio.replace('%', ''));
        console.log("qualityRatio", this.numericPanQualityRatio);

        if (this.numericPanQualityRatio < this.uploadedPanQuality) {
          this.$toast.error('Please upload a better quality image.', {
            timeout: 3000,
            position: 'top-right',
          });
          this.loader = false;
          this.$refs.pan.value = "";
          return;
        } else {
          this.panImageUrl = image;
          this.previewPan = URL.createObjectURL(image);
          this.uploadPanCard(image);
        }

      } catch (error) {
        console.error('Error checking image quality:', error);
        this.loader = false;
      }
    },

    toggleRoom100() {
      alert("Camera  toggleRoom100 ");
      if (!this.$parent.room2Active) {
        alert("Camera  toggleRoom100 done");
        console.log("reoom check ");
        this.roomJoined = true;
        if (this.roomId) {
          try {
            if (this.hasJoined) {
              this.$refs.webrtc2.leave();
              this.hasJoined = false;
            } else {
              this.socket.emit("joinRoom", this.roomId);
              setTimeout(() => (this.roomJoined = false), 1000);
              try {
                if (this.$refs.webrtc2) {
                  console.log("webrtc13", this.$refs);

                  this.$refs.webrtc2.join().then(() => {
                    this.userStream = this.$refs.webrtc2?.videoList[0]?.stream;
                    console.log("room id11", this.$refs);
                    this.initializeAudioContext();
                    this.mediaRecorder = new MediaRecorder(this.userStream);
                    this.mediaRecorder.ondataavailable = (e) => this.pushData(e);
                    this.mediaRecorder.onstop = () => this.onStop();
                    this.hasJoined = true;
                  }).catch(error => {
                    console.error("Failed to join room:", error);
                  });
                } else {
                  console.error("webrtc2 is null, check the component mounting");
                }
              } catch (error) {
                alert("Camera Not Found");
                return;
              }
            }
          } catch (e) {
            alert(e);
          }
        } else {
          console.log("room id not found");
        }
      }
    },

    handleRecognitionPercentage(percentage) {
      this.percentageValue = percentage;
      this.updateIdentityCheck();
    },
    async navigateToIdentityCheckDone() {
      let updateData = {
        identityCheck: this.identityCheck,
      };
      // updateData.identityCheck.uploadedPanQuality = this.isRandom()
      //   ? "Good"
      //   : "recheck";
      // updateData.identityCheck.livePhotographQuality = this.isRandom()
      //   ? "Good"
      //   : "recheck";
      // updateData.identityCheck.liveCapturedPanQuality = this.isRandom()
      //   ? "Good"
      //   : "recheck";
      // (updateData.identityCheck.faceMatch = "Issue"),
      //   (updateData.identityCheck.customerLiveliness = "Issue"),
      //   (updateData.identityCheck.randomQuestionnaire = "Questions");

      try {
        const id = this.$route.query.id;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.put(
          `${apiUrl}/case/update/${id}`,
          updateData
        );
        this.navigateToIdentyCheck(id);
        console.log("Update response:", response);
      } catch (error) {
        console.error("Update error:", error);
      }
      this.fetchCaseById3();
    },
    navigateToIdentyCheck(id) {
       this.$router.push("/vcipin?id="+ id)
      // location.reload();
      this.$emit("onLiveComplete", {
        message: "Live Accepteance is complete",
        someother: 1,
        id: id,
      });
    },
    async fetchCaseById3() {
      try {
        const id = this.$route.query.id;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.post(`${apiUrl}/case/getById/${id}`);
        this.caseData3 = response.data;
        console.log("Case data:", this.caseData3);
        this.flexDivDisplay = "flex!important";
        this.flexDivDisplay2 = "flex!important";
        this.flexDivDisplay3 = "flex!important";
      } catch (error) {
        console.error("Error fetching case data:", error);
      }
    },
    async getDataById() {
      try {
        const id = this.$route.query.id;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.post(`${apiUrl}/case/getById/${id}`);
        const data = response.data;
        console.log("API Response:", data);
        this.personalDetails.name = data.personalDetails.name;
        this.personalDetails.lastname = data.personalDetails.lastname;
        this.personalDetails.dob = data.personalDetails.dob;
        this.permanentAddress.addressLine1 = data.permanentAddress.addressLine1;
        this.permanentAddress.addressLine2 = data.permanentAddress.addressLine2;
        this.permanentAddress.addressLine3 = data.permanentAddress.addressLine3;
        this.permanentAddress.pincode = data.permanentAddress.pincode;
        this.permanentAddress.city = data.permanentAddress.city;
      } catch (error) {
        console.error("API Error:", error);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.normal-btn {
  color: black;
  border-radius: 4px;
  font-size: 12px;
  border: 1px solid black;
}

.selected {
  background-color: #ee8d33;
  color: white;
  border-radius: 4px;
  font-size: 12px;
  border: 1px solid #ee8d33;
}

hr {
  margin: 5px 0px;
}

.form-section {
  position: relative;
  /* padding: 40px 300px; */
  /* background-color: rgb(192, 192, 192); */
  min-height: 100vh;
  border-radius: 8px;
}

.login-page {
  background-color: #f4f4f4;
  border: 1px solid rgb(192, 192, 192);
  padding: 30px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.login-pageforBottom {
  background-color: #f4f4f4;
  /* border: 1px solid rgb(192, 192, 192);
  padding: 30px; */
  border-radius: 10px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.for-camera {
  width: 100%;
  height: 50vh;
  position: absolute;
}

.camera {
  width: 100%;
  height: 64vh;
  background-color: #39bb8f;
  padding: 2rem;
  border-radius: 8px;
  align-items: flex-end;
}

.av-test {
  width: 100%;
  background-color: white;
  height: 10vh;
  align-items: center;
  padding-inline: 1rem;
  border-bottom: 2px solid #e6e6e6;
}

.av-div1 {
  width: 100%;
}

.av-test-btn {
  padding: 5px 20px;
  border-radius: 20px;
  background-color: #ee8d33;
  border: 2px solid #eb780d;
  color: white;
  margin: 0px;
}

.i-h {
  color: grey;
}

@media (max-width: 768px) {
  .form-section {
    width: 100%;
    padding: 0px 0px;
  }

  .login-page {
    border: none;
    border-radius: 0px;
    min-height: 100vh;
    padding: 0px;
    justify-content: center;
  }

  .login-header {
    margin-bottom: 1rem;
  }

  .for-camera {
    margin-top: -11rem;
    height: 57vh;
    width: -webkit-fill-available;
  }

  .camera {
    height: 70vh;
    border-radius: 0px;
  }

  .av-test {
    /* font-size: 18px; */
    height: 60px;
  }

  .i {
    padding: 1rem;
  }
}

@media all and (orientation: landscape) and (min-width: 540px) and (max-width: 1024px) {
  .form-section {
    /* width: 100%; */
    padding: 0px 0px;
  }

  .login-page {
    border: none;
    border-radius: 0px;
    /* height:100vh; */
  }

  .login-header {
    /* margin-bottom: 1rem; */
    font-size: 18px;
    font-weight: 600;
  }

  /* .vcip-heading{
    font-size: 18px;
    font-weight: 600;
    text-align: start;
    margin-bottom: 5px;
  } */
  /* .acceptance-options{
    padding-top: 13px;
  } */


  .camera {
    height: 90vh;
    border-radius: 10px;
    margin-bottom: 2rem;
  }

  .i {
    padding: 1rem;
    font-size: 18px;
  }

  .av-test {
    font-size: 18px;
    height: 50px;
  }
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

input[type="checkbox"] {
  margin-right: 5px;
  vertical-align: middle;
}

button {
  margin-top: 10px;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}


[data-v-41044b41] {
  background: #c5c4c400 !important;
  display: inline-block;
}

.vue-webrtc {
  background: #c5c4c400 !important;
  /* Add any other styles you want to apply */
}


.video-list[data-v-41044b41] {
  background: whitesmoke;
  height: auto;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center;
  flex-wrap: inherit !important;

}

.av-test[data-v-0c218a7e] {
  width: 100%;
  background-color: #f4f4f4;
  height: 10vh;
  align-items: center;
  padding-inline: 0.3rem;
  border-bottom: 2px solid #e6e6e6;
  border-radius: 7px;
}

.gapping222 {
  gap: 324px
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Ensure it's above other content */
}

.loader-circle {
  border: 3px solid #f3f3f3;
  /* Light gray border */
  border-top: 3px solid #3498db;
  /* Blue border for the spinning circle */
  border-radius: 50%;
  /* Make it a circle */
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  /* Spin animation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.bounding-box {
  position: absolute;
  top: 50%; /* Adjust as needed */
  left: 50%; /* Adjust as needed */
  width: 250px; /* Define the bounding box size */
  height: 150px;
  border: 2px dashed red;
  transform: translate(-50%, -50%);
  z-index: 10; /* Ensure it overlays the video */
  pointer-events: none;
}
</style>
